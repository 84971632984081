<template>
  <div class="row">
    <div class="col-md-4 mx-auto">
      <form class="form card card-body p-5">
        <h5 class="text-center mb-4 mt-3">メールの送信が完了しました</h5>
        <div class="form-group mx-auto">
          <p class="text-left">パスワードリセットに必要なメールを送信しました。
            メール記載のリンクからパスワードの再設定に進んでください。
          </p>
        </div>
        <button class="btn btn-dark font-weight-bold mx-auto mt-5 col-8" type="submit" @click="login">ログインに戻る</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResetPasswordEmailSent",
  methods: {
    login(e) {
      e.preventDefault()
      this.$router.push({
        name: 'Login'
      });
    },
  }
}
</script>

<style scoped>

</style>